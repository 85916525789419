@import "styles/global/_colors.scss";

.App {
  text-align: center;
  color: $text-color;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--content {
    width: 800px;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: $text-color;

    border: 0.2rem solid #fff;
    border-radius: 2rem;
    padding: 0.4em;
    box-shadow: 0 0 .2rem #fff,
              0 0 .2rem #fff,
              0 0 2rem #bc13fe,
              0 0 0.8rem #bc13fe,
              0 0 2.8rem #bc13fe,
              inset 0 0 1.3rem #bc13fe; 
    margin-bottom: 4rem;

    h3 {
      font-size: smaller;
    }
    
    ul {
      display: flex;
      flex-direction: column;
    }
    &__incrementer {
      display: flex;
      align-items: center;
      &--current-count {
        width: 100px;
      }
    }

    &__about_profile_container {
      display: flex;
      flex-direction: column;
      max-width: 33%;
    }
    &__header {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-right: 10%;
      margin-top: 5%;
      font-style: italic;
      font-weight: bolder;
    }
    &__footer {
      font-size: smaller;
    }

    &__preview-container {
      display: flex;
      width: 512px;

      

      &--preview {
        border-radius: 50%;
        width: 100%;
        padding: 32px;
      }

    }

    &__social-buttons {
      display: flex;
      justify-content: space-evenly;

      width: 100%;
    }

    .glow-on-hover {
      width: 175px;
      height: 50px;
      border: none;
      outline: none;
      color: #fff;
      background: #111;
      cursor: pointer;
      position: relative;
      z-index: 0;
      border-radius: 10px;
    }
    
    .glow-on-hover:before {
      content: '';
      background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
      position: absolute;
      top: -2px;
      left:-2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      border-radius: 10px;
    }
    
    .glow-on-hover:active {
      color: #000
    }
    
    .glow-on-hover:active:after {
      background: transparent;
    }
    
    .glow-on-hover:hover:before {
      opacity: 1;
    }
    
    .glow-on-hover:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #111;
      left: 0;
      top: 0;
      border-radius: 10px;
    }

    .font-size-xx {
      font-size: xx-large;
      width: 75px;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $text-color;
}

.App-link {
  color: $primary-color;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}
